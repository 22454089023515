import { onMounted, onUnmounted } from 'vue'

interface SessionTrackerOptions {
  onIdle: () => Promise<void>
  interval?: number
  duration?: number
}

export const useSessionTimeTracker = ({
  interval = 60,
  duration = 120,
  onIdle,
}: SessionTrackerOptions) => {
  let isActive = true

  const updateTimeInterval = setInterval(async () => {
    await refreshSessionTime()
  }, interval * 1000)

  onMounted(() => {
    const curr_time = new Date().getTime()
    sessionStorage.setItem('session-time', String(curr_time))

    window.addEventListener('focus', onWindowFocus)
    window.addEventListener('blur', onWindowBlur)
  })

  onUnmounted(() => {
    window.removeEventListener('focus', onWindowFocus)
    window.removeEventListener('blur', onWindowBlur)
    sessionStorage.removeItem('session-time')
    clearInterval(updateTimeInterval)
  })

  const refreshSessionTime = async () => {
    const curr_time = new Date().getTime()
    const last_time = sessionStorage.getItem('session-time')
    const diff = curr_time - Number(last_time)

    if (diff > duration * 1000) {
      await onIdle()
    } else if (isActive) {
      sessionStorage.setItem('session-time', String(curr_time))
    }
  }

  const onWindowFocus = async () => {
    isActive = true
    const curr_time = new Date().getTime()
    const last_time = sessionStorage.getItem('session-time')
    const diff = curr_time - Number(last_time)

    if (diff > duration * 1000) {
      await onIdle()
    }
  }

  const onWindowBlur = () => {
    isActive = false
    const curr_time = new Date().getTime()
    sessionStorage.setItem('session-time', String(curr_time))
  }
}

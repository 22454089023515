import * as DocoTypes from '@/types/DocoTypes'

import { defineStore, acceptHMRUpdate } from 'pinia'
import { useRequest } from '@/services/api/useRequest'
import { Urls } from '@/services/api/Urls'
import {
  ConfigurationFields,
  getInputNameByFieldName,
} from '@/stores/configuration/configurationKeys'
import {
  getPolicyFieldValueFormatter,
  getMenuItemName,
} from '@/stores/configuration/configurationStoreUtils'
import {
  ConfigurationType,
  ConfigurationSection,
  FieldConfigurationType,
} from '@/constants'
import { useMenuItems } from '@/components/templates/navigation/menuItems'
import { Stores } from '@/stores/Stores'
import { formatSentenceCase } from '@/utils/textFormatter'
import { useAccessLogService } from '@/services/api/useAccessLogService'

/** @typedef {'featuresConfiguration' | 'featureConfiguration' | 'systemConfiguration'} RequestName */

export const useConfigurationStore = defineStore(Stores.ConfigurationStore, {
  state: () => ({
    /** @type {DocoTypes.InitializeDataFeatureModel[]} */
    features: [],
    /** @type {DocoTypes.InitializeDataConfigurationModel[]} */
    configurations: [],
    /** @type {DocoTypes.InitializeDataCurrencyModel[]} */
    currencies: [],
    menu: {
      selectedMenu: null,
    },
    featureConfigurationId: null,
    systemConfigurationId: null,
    /** @type {Record<RequestName, AbortController | null>} */
    abortController: {
      featuresConfiguration: null,
      featureConfiguration: null,
      systemConfiguration: null,
    },
    /** @type {Record<RequestName, boolean>} */
    isLoading: {
      featuresConfiguration: false,
      featureConfiguration: false,
      systemConfiguration: false,
    },
  }),
  getters: {
    getDisabledSections: (state) =>
      state.features.filter((x) => !x.isEnabled).map((x) => getMenuItemName(x.name)),
    selectedMenu: (state) => state.menu?.selectedMenu,
    getCurrencies: (state) => state.currencies,
    getCurrenciesForMultiselector: (state) => {
      const allOption = state.currencies.find(
        (option) => option.isoCurrencySymbol.toLowerCase() === 'all'
      )
      const currenciesSorted = state.currencies
        .filter((option) => option.isoCurrencySymbol.toLowerCase() !== 'all')
        .sort((a, b) => a.isoCurrencySymbol.localeCompare(b.isoCurrencySymbol))

      return [allOption, ...currenciesSorted].map((currency) => ({
        id: `${currency?.currencySymbol} ${currency?.isoCurrencySymbol}`,
        label: currency?.isoCurrencySymbol,
      }))
    },

    getBaseCurrency: (state) => {
      const financials = state.configurations.find(
        (config) => config.name === 'Financials'
      )
      if (financials !== undefined) {
        const baseCurrency = financials.systemConfigurations.find(
          (config) => config.name === 'Base Currency'
        )
        return baseCurrency !== undefined ? baseCurrency.value : ''
      }

      return ''
    },

    getFinancialsInput: (state) => {
      const financials = state.configurations.find(
        (config) => config.name === 'Financials'
      )
      if (financials !== undefined) {
        const financialInput = financials.systemConfigurations.find(
          (config) => config.name === 'Financial Input'
        )
        return financialInput !== undefined ? financialInput.value : ''
      }

      return ''
    },

    getCurrencySymbol: (state) => (currency) => {
      const symbol = state.currencies.find((x) => x.isoCurrencySymbol === currency)

      return symbol !== undefined ? symbol.currencySymbol : ''
    },

    getBaseCurrencySymbol: (state) => {
      const baseCurrency = state.getBaseCurrency
      const symbol = state.currencies.find((x) => x.isoCurrencySymbol === baseCurrency)

      return symbol !== undefined ? symbol.currencySymbol : ''
    },

    claimCreationConfiguration: (state) =>
      state.features.find((x) => x.name == ConfigurationType.ClaimCreation),

    getSanctionsConfiguration: (state) =>
      state.features.find((x) => x.name == ConfigurationType.Sanctions),

    getDOCOinsightsConfiguration: (state) =>
      state.features.find((x) => x.name == ConfigurationType.DOCOinsights),

    getEmailClaimsConfiguration: (state) =>
      state.features.find((x) => x.name == ConfigurationType.EmailClaims),

    getIsEmaiClaimsFeatureEnabled: (state) =>
      state.features.find((x) => x.name == ConfigurationType.EmailClaims)?.isEnabled,

    getHiddenFields: (state) => {
      return state.allFieldsConfiguration
        .filter((x) => x.isVisible?.value === 'false')
        .map((x) => getInputNameByFieldName(x.name))
    },

    getPaymentMethodEnabled: (state) => (paymentName) => {
      return (
        state.features
          .find((x) => x.name == ConfigurationType.Payments)
          ?.details.find(
            (x) =>
              x.name.toUpperCase() == ConfigurationFields.paymentMethod.name.toUpperCase()
          )
          ?.configurations.find((x) => x.name.toUpperCase() == paymentName.toUpperCase())
          ?.value === 'true'
      )
    },

    allFieldsConfiguration: (state) => {
      return state.features
        .flatMap((firstLevel) => {
          return firstLevel.details.flatMap((secondLevel) => {
            return {
              name: secondLevel.name,
              isMandatory: secondLevel.configurations.find(
                (x) =>
                  x.name.toUpperCase() ===
                  FieldConfigurationType.IsMandatory.toUpperCase()
              ),
              isVisible: secondLevel.configurations.find(
                (x) =>
                  x.name.toUpperCase() === FieldConfigurationType.IsVisible.toUpperCase()
              ),
              configType: firstLevel.name,
              section: secondLevel.section,
            }
          })
        })
        .concat(state.getNotesSettins)
    },

    getNotesSettins: (state) => {
      let notesMandatory = false
      let categoryMandatory = false
      const descConfig = state.features.find(
        (x) => x.name === ConfigurationType.ClaimCreation
      )
      if (descConfig) {
        const notes = descConfig.details.find(
          (x) => x.name === ConfigurationSection.Notes
        )

        if (notes) {
          notesMandatory = notes.configurations.find(
            (x) => x.name === FieldConfigurationType.IsMandatory
          ).value
        }
      }
      const catConfig = state.features.find((x) => x.name === ConfigurationType.Notes)
      if (catConfig) {
        const notes = catConfig.details.find(
          (x) => x.name === ConfigurationSection.Category
        )

        if (notes) {
          categoryMandatory = notes.configurations.find(
            (x) => x.name === FieldConfigurationType.IsMandatory
          ).value
        }
      }

      return [
        {
          configType: ConfigurationType.ClaimCreation,
          isMandatory: {
            name: FieldConfigurationType.IsMandatory,
            type: 'bool',
            value: categoryMandatory,
          },
          isVisible: {
            name: FieldConfigurationType.IsVisible,
            type: 'bool',
            value: 'true',
          },
          name: ConfigurationFields.noteCategory.name,
          section: ConfigurationSection.Notes,
        },
        {
          configType: ConfigurationType.ClaimCreation,
          isMandatory: {
            name: FieldConfigurationType.IsMandatory,
            type: 'bool',
            value: notesMandatory,
          },
          isVisible: {
            name: FieldConfigurationType.IsVisible,
            type: 'bool',
            value: 'true',
          },
          name: ConfigurationFields.noteDescription.name,
          section: ConfigurationSection.Notes,
        },
        {
          configType: ConfigurationType.ClaimCreation,
          isMandatory: {
            name: FieldConfigurationType.IsMandatory,
            type: 'bool',
            value: notesMandatory,
          },
          isVisible: {
            name: FieldConfigurationType.IsVisible,
            type: 'bool',
            value: 'true',
          },
          name: ConfigurationFields.notefavourite.name,
          section: ConfigurationSection.Notes,
        },
      ]
    },

    allFieldsConfigurationWithTypesAndSections:
      (state) =>
      ({ configType, section = null }) => {
        return {
          configuration: state.allFieldsConfiguration,
          configType,
          section,
        }
      },

    getFeatureConfigurationNames: (state) =>
      state.features
        .map((x) => ({ label: x.name, id: x.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),

    getSystemConfigurationNames: (state) =>
      state.configurations
        .map((x) => ({ label: x.name, id: x.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),

    getFeatureConfiguration: (state) => {
      return state.features.find((x) => x.id == state.featureConfigurationId)
    },
    getSystemConfiguration: (state) => {
      return state.configurations.find((x) => x.id == state.systemConfigurationId)
    },
    getPolicyFieldsConfiguration: (state) => {
      return (
        state.features
          .find((x) => x.name == ConfigurationType.Policy)
          ?.details.filter(
            (x) =>
              x.configurations.find(
                (y) =>
                  y.name == FieldConfigurationType.IsDisplayInPolicyOverview &&
                  y.value.toLowerCase() === 'true'
              ) !== undefined
          )
          .filter((x) => x.name != ConfigurationFields.policyReference.name)
          .map((x) => {
            const fieldType = x.configurations.find(
              (x) => x.name === FieldConfigurationType.FieldType
            )?.value

            return { ...x, valueFormatter: getPolicyFieldValueFormatter(fieldType) }
          })
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      )
    },
    getAllPolicyFieldsConfiguration: (state) => {
      return (
        state.features
          .find((x) => x.name == ConfigurationType.Policy)
          ?.details.filter((x) => x.name != ConfigurationFields.policyReference.name)
          .map((x) => {
            const fieldType = x.configurations.find(
              (x) => x.name === FieldConfigurationType.FieldType
            )?.value

            return { ...x, valueFormatter: getPolicyFieldValueFormatter(fieldType) }
          })
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      )
    },
    getAllVisiblePolicyFieldsConfiguration: (state) => {
      const policyFieldsVisible =
        state.features
          .find((x) => x.name == ConfigurationType.Policy)
          ?.details?.filter((x) => {
            const isFieldSetToVisible =
              x.configurations.find((x) => x.name === FieldConfigurationType.IsVisible)
                ?.value === 'true'

            return isFieldSetToVisible
          }) ?? []

      return policyFieldsVisible
        .map((x) => {
          const fieldType = x.configurations.find(
            (x) => x.name === FieldConfigurationType.FieldType
          )?.value

          return { ...x, valueFormatter: getPolicyFieldValueFormatter(fieldType) }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    /** @type {(state: any) => {name: string, isEnabled: boolean}[]} */
    getAllPaymentMethodsConfiguration: (state) => {
      return (
        state.features
          .find((x) => x.name == ConfigurationType.Payments)
          ?.details.filter((x) => x.name == ConfigurationFields.paymentMethod.name)
          .flatMap((d) => d.configurations)
          .filter((x) => x.type === 'bool')
          .map((x) => ({
            name: x.name,
            isEnabled: x.value.toLowerCase() == 'true',
          }))
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      )
    },
    /** @type {(state: any) => string[]} */
    getAvailablePaymentMethodsNames: (state) => {
      return (
        state.features
          .find((x) => x.name == ConfigurationType.Payments)
          ?.details.filter((x) => x.name == ConfigurationFields.paymentMethod.name)
          .flatMap((d) => d.configurations)
          .filter((x) => x.type === 'bool' && x.value.toLowerCase() == 'true')
          .map((x) => x.name)
          .sort((a, b) => `${a}`.localeCompare(`${b}`)) ?? []
      )
    },
    getPolicyFieldsIsIncludeInReport: (state) => (report) => {
      return (
        state.features
          .find((x) => x.name == ConfigurationType.Policy)
          ?.details.filter(
            (x) =>
              x.configurations.find(
                (y) => y.name == report && y.value.toLowerCase() === 'true'
              ) !== undefined
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((x) => {
            x.field = x.name
            x.fieldType = x.configurations.find(
              (y) => y.name == FieldConfigurationType.FieldType
            )?.value

            return x
          }) ?? []
      )
    },
    getPolicyFieldIsVisibleFeatureConfiguration: (state) => (policyFieldName) => {
      return state.features
        .find((x) => x.name == ConfigurationType.Policy)
        ?.details.find((x) => x.name.toUpperCase() == policyFieldName.toUpperCase())
        ?.configurations.map((x) => ({
          name: x.name,
          isVisible: x.value.toLowerCase() == 'true',
        }))
        .find(
          (x) => x.name.toUpperCase() === FieldConfigurationType.IsVisible.toUpperCase()
        )
    },
    /** @type {(state: any) => {fieldName: string, fieldType: string}[]} */
    getPolicyFieldsIncludedInPolicySearch: (state) =>
      state.features
        .find((x) => x.name == ConfigurationType.Policy)
        ?.details.filter(
          (x) =>
            !!x.configurations.find(
              (y) =>
                y.name == FieldConfigurationType.IncludeInPolicySearch &&
                `${y.value}`.toLowerCase() === 'true'
            )
        )
        .map((x) => ({
          fieldName: x.name,
          fieldType: x.configurations.find(
            (y) => y.name == FieldConfigurationType.FieldType
          )?.value,
        }))
        .sort((a, b) => a.fieldName.localeCompare(b.fieldName)) ?? [],
    /** @type {(state: any) => ({name: string, fieldType: string, isVisible: boolean}[])} */
    getInboxVisiblePolicyFields: (state) => {
      return (
        state.features
          .find((feature) => feature.name === ConfigurationType.Inbox)
          ?.details.map((field) => ({
            name: field.name,
            isVisible:
              field.configurations.find(
                (config) => config.name === FieldConfigurationType.IsVisible
              )?.value === 'true',
            fieldType: field.configurations.find(
              (config) => config.name === FieldConfigurationType.FieldType
            )?.value,
          }))
          .filter((field) => field.isVisible)
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      )
    },
    isAnyLoading: (state) => Object.values(state.isLoading).some((x) => x),
  },
  actions: {
    reset() {
      this.features = []
      this.configurations = []
      this.currencies = []
      this.menu = null
    },
    async setupFeaturesConfiguration() {
      const { get } = useRequest()

      /** @type {DocoTypes.InitializeDataModel} */
      const initializeData = await get(Urls.configurationStore.initialize)

      this.features = initializeData.Features ?? []
      this.configurations = initializeData.Configurations ?? []
      this.currencies = initializeData.Currencies ?? []
    },
    setSelectedMenu(item) {
      const { getMenuItem } = useMenuItems(
        this.getFeatureConfigurationNames,
        this.getSystemConfigurationNames
      )

      const menuItem = getMenuItem(item)

      if (!menuItem && this.menu) {
        this.menu.selectedMenu = item
        return
      }

      if (this.menu) this.menu.selectedMenu = menuItem.parent ?? menuItem.name
    },
    setFeatureConfigurationId({ id }) {
      this.featureConfigurationId = id
    },
    setSystemConfigurationId({ id }) {
      this.systemConfigurationId = id
    },
    async setFeatureConfiguration(skipSort) {
      const { backgroundGet } = useRequest()

      await backgroundGet(
        Urls.configuration.featureById(this.featureConfigurationId),
        this.abortController,
        this.isLoading,
        'featureConfiguration',
        {
          onSuccess: (response) => {
            const index = this.features.findIndex((feature) => feature.id === response.id)
            if (index !== -1) {
              const details = response.details.map((details) => ({
                ...details,
                configurations: skipSort
                  ? details.configurations
                  : details.configurations.sort((a, b) => a.name.localeCompare(b.name)),
              }))
              this.features[index] = { ...response, details: details }

              const { logEvent } = useAccessLogService()
              logEvent({
                eventName: `Feature configuration - ${formatSentenceCase(
                  this.features[index].name
                )} viewed`,
              })
            }
          },
        }
      )
    },
    async setSystemConfiguration() {
      const { backgroundGet } = useRequest()

      await backgroundGet(
        Urls.configuration.system(this.systemConfigurationId),
        this.abortController,
        this.isLoading,
        'systemConfiguration',
        {
          onSuccess: (response) => {
            const index = this.configurations.findIndex(
              (configuration) => configuration.id === response.id
            )
            if (index !== -1) {
              this.configurations[index] = response
            }

            const { logEvent } = useAccessLogService()
            logEvent({
              eventName: `System configuration - ${formatSentenceCase(
                this.configurations[index].name
              )} viewed`,
            })
          },
        }
      )
    },
    cancelRequests() {
      Object.keys(this.abortController).forEach((key) => {
        if (this.abortController[key]) {
          this.abortController[key].abort()
          this.abortController[key] = null
          this.isLoading[key] = false
        }
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigurationStore, import.meta.hot))
}
